<template>
  <v-textarea :value="value"
              @input="onUpdateValue"
              outlined
              no-resize
              :disabled="isReadSurvey || options.readonly"
  />
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'TextFree',

  mixins: [questionMixins],
}
</script>
